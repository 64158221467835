// 获取现有成员
import {
  delGroupUser,
  findGroupRole,
  findUserByGroupId,
  updGroupRole,
} from "./groupApi";
import { ElMessage, ElMessageBox } from "element-plus";

export const getUserLists = (data) => {
  data.loading = true;
  let param = {
    // groupId: JSON.parse(sessionStorage.getItem('classInfo')).groupId,
    groupId: JSON.parse(sessionStorage.getItem("groupId")),
    pageSize: data.pageSize,
    page: data.currentPage,
  };
  findUserByGroupId(param).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.peopleData = newRes.groupUsersPageInfo.list;
      data.total = newRes.groupUsersPageInfo.total;
      data.peopleOptions = data.peopleData.map((v) => {
        return { userId: v.courseGroupUserId, name: v.name };
      });
      setTimeout(() => {
        data.loading = false;
      }, 300);
    }
  });
};

export const getAllUserLists = (data) => {
  data.loading = true;
  let param = {
    // groupId: JSON.parse(sessionStorage.getItem('classInfo')).groupId,
    groupId: JSON.parse(sessionStorage.getItem("groupId")),
    pageSize: 1000,
    page: 1,
  };
  findUserByGroupId(param).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.peopleOptions = newRes.groupUsersPageInfo.list.map((v) => {
        return { userId: v.courseGroupUserId, name: v.name };
      });
      setTimeout(() => {
        data.loading = false;
      }, 300);
    }
  });
};

// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  getUserLists(data);
};

// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  getUserLists(data);
};

// 点击删除功能
export const clickButtons = (val, data) => {
  if (val.type === "删除") {
    ElMessageBox.confirm("此操作将永久删除该成员, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      delUserPeople(val.item, data);
    });
  }
};

// 删除 用户
export const delUserPeople = (val, data) => {
  delGroupUser({ groupUserId: val.courseGroupUserId }).then((res) => {
    if (res.data.code === "200") {
      ElMessage.success(res.data.msg);
      getUserLists(data);
    } else {
      ElMessage.error(res.data.msg);
    }
  });
};
// 职位变更
export const peopleChanges = (val, data) => {};

export const getUserTypes = (data) => {
  findGroupRole().then((res) => {
    if (res.data.code === "200") {
      data.nowJobOptions = res.data.data.memberRoles;
    }
  });
};
// 职务变更按钮
export const alterJobs = (data) => {
  let parame = {
    groupId: JSON.parse(sessionStorage.getItem("groupId")),
    courseGroupUser: {
      courseGroupUserId: data.peopleValue,
      memberRoleId: data.nowJob,
    },
  };
  updGroupRole(parame).then((res) => {
    if (res.data.code === "200") {
      ElMessage.success(res.data.msg);
      data.peopleValue = "";
      data.nowJob = "";
      getUserLists(data);
    } else {
      ElMessage.error(res.data.msg);
    }
  });
};
